<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-table
        ref="refWorkcenterListTable"
        class="position-relative"
        :items="row"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        selectable
        select-mode="single"
        empty-text="No matching records found"
        @row-clicked="getBreakDowns"
      >
        <template #head()="data">
          <span>{{ $t(data.label) }}</span>
        </template></b-table
      >
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, provide } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import moment from "moment";
import useWorkcenterList from "./useWorkcenterList";
import breakdownStoreModule from "../breakdownStoreModule";
import useBreakdownList from "../breakdown-pamis-list/useBreakdownList";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      selectedWorkcenter: null,
      search: "",
      actionType: 0,
    };
  },

  computed: {
    isActiveVariant() {
      const priorityColor = {
        false: "light-danger",
        true: "light-success",
      };

      return (priority) => priorityColor[priority];
    },
  },
  created() {
    this.fillRow();
  },
  methods: {
    fillRow() {
      // this.row = [
      //   { id: 9, name: 'PT7' },
      //   { id: 8, name: 'PT9' },
      //   { id: 11, name: 'PT11' },
      //   { id: 3, name: 'PT12' },
      //   { id: 10, name: 'PT13' },
      //   { id: 1, name: 'PT15' },
      //   { id: 5, name: 'PT19' },
      //   { id: 7, name: 'PT20' },
      //   { id: 2, name: 'PT23' },
      //   { id: 4, name: 'PT24' },
      //   { id: 80, name: 'PT27' },
      //   { id: 81, name: 'PT44' },
      //   { id: 6, name: 'PT63' },
      // ]
    },
    getBreakDowns(item) {
      this.$emit("clicked", item);
    },
    format_date(value) {
      if (value) {
        const myDate = new Date("0001-01-01T00:00:00Z");
        const dateItem = moment(value);

        if (dateItem > myDate) {
          return moment(value).locale("en").format("LLL");
        }
      }
    },
  },
  setup() {
    const PAMIS_MODULE_NAME = "pamis-breakdown-workcenter";
    // Register module
    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, breakdownStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME))
        store.unregisterModule(PAMIS_MODULE_NAME);
    });

    const {
      row,
      tableColumns,
      perPage,
      currentPage,
      totalBreakdowns,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refWorkcenterListTable,
      refetchData,
      search,
    } = useWorkcenterList();

    const { GetBreakdownDatas } = useBreakdownList();
    return {
      row,
      tableColumns,
      perPage,
      currentPage,
      totalBreakdowns,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refWorkcenterListTable,
      refetchData,
      search,
      // Filter
      avatarText,
      GetBreakdownDatas,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
