import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWorkcenterList() {
  // Use toast
  const toast = useToast()
  const refWorkcenterListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Workcenters', sortable: false },
  ]
  const perPage = ref(10)
  const totalBreakdowns = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const dataWorkcenters = ref([])
  const search = ref('')
  const workcenters = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refWorkcenterListTable.value ? refWorkcenterListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalBreakdowns.value,
    }
  })

  const refetchData = () => {
    refWorkcenterListTable.value.refresh()
    this.GetData()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })

  // function GetWorkcenters() {
  //   store.dispatch('pamis-breakdown/fetchWorkCenters')
  //     .then(response => {
  //       const workcenterResult = response.data.filter(x => x.isDelete == false)
  //       row.value = workcenterResult
  //       totalBreakdowns.value = workcenterResult.length

  //       row.value.forEach(element => {
  //         const workcenterlist = dataWorkcenters.value.filter(x => x.id == element.breakdownGUID)

  //         if (workcenterlist != null && workcenterlist.length > 0) {
  //           element.breakdown = workcenterlist[0].breakdownName
  //         }
  //       })
  //     })
  //     .catch(() => {
  //       toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: 'Error fetching Breakdown list',
  //           icon: 'AlertTriangleIcon',
  //           variant: 'danger',
  //         },
  //       })
  //     })
  // }

  // function GetDataColectionBreakdowns() {
  //   return new Promise((resolve, reject) => {
  //     store.dispatch('pamis-breakdown/fetchBreakdowns')
  //       .then(response => {
  //         let workcenterResult = []

  //         if (response.data != null && response.data.length > 0) {
  //           workcenterResult = response.data.filter(x => x.isDelete == false && x.isActive == true)
  //           resolve(workcenterResult)
  //           dataWorkcenters.value = workcenterResult
  //         } else {
  //           reject(workcenterResult)
  //         }
  //       })
  //       .catch(() => {
  //         // reject(workcenterResult)
  //         dataWorkcenters.value = [{id: '9', name: 'PT7' },
  //         { id: '8', name: 'PT9'  },
  //         { id: '11', name: 'PT11' },
  //         { id: '3', name: 'PT12'  },
  //         { id: '10', name: 'PT13' },
  //         { id: '1', name: 'PT15'  },
  //         { id: '5', name: 'PT19'  },
  //         { id: '7', name: 'PT20'  },
  //         { id: '2', name: 'PT23'  },
  //         { id: '4', name: 'PT24'  },
  //         { id: '80', name: 'PT27' },
  //         { id: '81', name: 'PT44' },
  //         { id: '6', name: 'PT63'  }]
  //       })
  //   })
  // }
  function GetWorkCenters() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-breakdown/fetchWorkCenters')
        .then(response => {
          let breakdownTypeResult = []

          if (response.data != null && response.data.length > 0) {
            breakdownTypeResult = response.data
            resolve(breakdownTypeResult)
            workcenters.value = breakdownTypeResult
            row.value = breakdownTypeResult
            // row.value = [
            //   { id: 100, name: 'PT7' },
            //   { id: 101, name: 'PT9' },
            //   { id: 102, name: 'PT11' },
            //   { id: 103, name: 'PT12' },
            //   { id: 104, name: 'PT13' },
            //   { id: 105, name: 'PT15' },
            //   { id: 106, name: 'PT19' },
            //   { id: 107, name: 'PT20' },
            //   { id: 108, name: 'PT23' },
            //   { id: 109, name: 'PT24' },
            //   { id: 110, name: 'PT27' },
            //   { id: 111, name: 'PT44' },
            //   { id: 112, name: 'PT63' },
            // ]
          } else {

            // reject(breakdownTypeResult)
            // row.value = [
            //   { id: 100, name: 'PT7' },
            //   { id: 101, name: 'PT9' },
            //   { id: 102, name: 'PT11' },
            //   { id: 103, name: 'PT12' },
            //   { id: 104, name: 'PT13' },
            //   { id: 105, name: 'PT15' },
            //   { id: 106, name: 'PT19' },
            //   { id: 107, name: 'PT20' },
            //   { id: 108, name: 'PT23' },
            //   { id: 109, name: 'PT24' },
            //   { id: 110, name: 'PT27' },
            //   { id: 111, name: 'PT44' },
            //   { id: 112, name: 'PT63' },
            // ]
          }
        })
        .catch(() => {
          // reject(breakdownTypeResult)
          row.value = [
            { id: 100, name: 'PT7' },
            { id: 101, name: 'PT9' },
            { id: 102, name: 'PT11' },
            { id: 103, name: 'PT12' },
            { id: 104, name: 'PT13' },
            { id: 105, name: 'PT15' },
            { id: 106, name: 'PT19' },
            { id: 107, name: 'PT20' },
            { id: 108, name: 'PT23' },
            { id: 109, name: 'PT24' },
            { id: 110, name: 'PT27' },
            { id: 111, name: 'PT44' },
            { id: 112, name: 'PT63' },
          ]
        })
    })
  }

  async function GetData() {
    // await GetDataColectionBreakdowns()
    await GetWorkCenters()
  }

  GetData()

  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalBreakdowns,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refWorkcenterListTable,
    refetchData,
  }
}
