import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getbreakdown from "@/mockdata/getbreakdown.json"
import getbreakdowndata from "@/mockdata/downtimesgetbreakdowndata.json"
import i18n from '@/libs/i18n'

export default function useBreakdownList() {
  // Use toast
  const toast = useToast()
  const refBreakdownListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'breakdownName', label: i18n.t('Breakdown Name'), sortable: true },
    { key: 'isPlanned', label: i18n.t('Is Planned'), sortable: false },
    { key: 'startDate', label: i18n.t('Start Date'), sortable: false },
    { key: 'endDate', label: i18n.t('End Date'), sortable: false },
    { key: 'duration', label: i18n.t('Duration'), sortable: false },
    { key: 'action', label: i18n.t('Action'), sortable: false }
  ];
  const tableExcelColumns = [
    { key: 'id', sortable: true },
    { key: 'breakdownName', label: i18n.t('Breakdown Name'), sortable: true },
    { key: 'isPlanned', label: i18n.t('Is Planned'), sortable: false },
    { key: 'startDate', label: i18n.t('Start Date'), sortable: false },
    { key: 'endDate', label: i18n.t('End Date'), sortable: false },
    { key: 'duration', label: i18n.t('Duration'), sortable: false },
    { key: 'action', label: i18n.t('Action'), sortable: false }
  ];

  const perPage = ref(10)
  const totalBreakdowns = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const dataCollectionBreakdowns = ref([])
  const search = ref('')
  // const workcenters = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refBreakdownListTable.value ? refBreakdownListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalBreakdowns.value,
    }
  })

  const refetchData = () => {
    refBreakdownListTable.value.refresh()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })


  const GetBreakdownDatas = (workCenterID, dateFilterId) => {

    const data = {

      workCenterID,

      dateFilterId,

    }
    GetDataColectionBreakdowns(workCenterID)
    store.dispatch('pamis-breakdown/fetchBreakdownData', data)

      .then(response => {

        const breakdownResult = response.data.filter(x => true)

        row.value = breakdownResult

        totalBreakdowns.value = breakdownResult.length

      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Breakdown list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }



  function GetDataColectionBreakdowns(workCenterID) {
    const data = {
      workCenterID,
    }

    return new Promise((resolve, reject) => {
      store.dispatch('pamis-breakdown/fetchBreakdowns', data)
        .then(response => {
          let breakdownResult = []
          if (response.data != null && response.data.length > 0) {
            breakdownResult = response.data.map(item => ({
              id: item.id,
              name: `${item.code} - ${item.name}`,
            }))
            resolve(breakdownResult)
            dataCollectionBreakdowns.value = breakdownResult
          } else {
            reject(breakdownResult)
          }
        })
        .catch(() => {
        })
    })
  }



  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalBreakdowns,
    dataMeta,
    perPageOptions,
    tableExcelColumns,
    sortBy,
    isSortDirDesc,
    refBreakdownListTable,
    refetchData,
    GetBreakdownDatas,
    dataCollectionBreakdowns,
  }
}
